import React from 'react';
import './navbar.css';

function Navbar() {
  return (
    <div>
      <nav className="navbar">
        <a className="navbar-brand" href="#">Tour Packages</a>
        <ul className="navbar-nav">
          <li className="nav-item"><a className="nav-link" href="#home">Home</a></li>
          <li className="nav-item"><a className="nav-link" href="#packages">Packages</a></li>
          <li className="nav-item"><a className="nav-link" href="#destinations">Destinations</a></li>
          <li className="nav-item"><a className="nav-link" href="#about">About Us</a></li>
          <li className="nav-item"><a className="nav-link" href="#contact">Contact</a></li>
        </ul>
      </nav>

      <div id="home" className="content-section">Home Content</div>
      <div id="packages" className="content-section">Packages Content</div>
      <div id="destinations" className="content-section">Destinations Content</div>
      <div id="about" className="content-section">About Us Content</div>
      <div id="contact" className="content-section">Contact Content</div>
    </div>
  );
}

export default Navbar;
